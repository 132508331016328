<template>
  <div id="PI">
    <transition name="load">
      <div class="loading" v-if="loading" key="1">
        <img src="../../assets/loading.gif" alt="" />
      </div>
      <div class="PI-inner" v-else key="2">
        <div class="PI-left">
          <h1>Informasi Tanaman</h1>
          <h2>{{ data.nama }}</h2>
          <!-- <p class="PI-time">Monday, November 22, 2020</p>
        <div class="PI-left-mid">
          <p>1 hour ago</p>
          <p>Author</p>
        </div> !-->
          <div class="PI-left-main">
            <img :src="data.foto" alt="" />
            <div class="PI-main-txt">
              <div class="PI-txt-up">
                <div class="PI-up-left">
                  <h6>Keluarga</h6>
                  <p>{{ data.keluarga }}</p>
                </div>
                <div class="PI-up-right">
                  <h6>Genus</h6>
                  <p>{{ data.genus }}</p>
                </div>
              </div>

              <div class="PI-txt-down">
                <div class="PI-txt">
                  <h3>Kondisi Terbaik</h3>
                </div>
                <div class="PI-temp">
                  <h6>Temperatur</h6>
                  <p>{{ data.suhu }}</p>
                </div>
                <div class="PI-soilN">
                  <h6>Nutrisi Tanah</h6>
                  <p>{{ data.ec }}</p>
                </div>
                <div class="PI-int">
                  <h6>Cahaya</h6>
                  <p>{{ data.cahaya }}</p>
                </div>
                <div class="PI-hum">
                  <h6>Kelembapan Udara</h6>
                  <p>{{ data.rh }}</p>
                </div>
                <div class="PI-soilH">
                  <h6>Kelembapan Tanah</h6>
                  <p>{{ data.tanah }}</p>
                </div>
                <div class="PI-ph">
                  <h6>pH</h6>
                  <p>{{ data.ph }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="PI-desc">
            <h5>Deskripsi</h5>
            <p style="white-space: pre-line">{{ data.deskripsi }}</p>
          </div>
        </div>
        <div class="PI-right">

          <div class="PI-search">
            <button v-on:click="back()" class="search">
              <p>&lt;&lt; Kembali</p>
              <!-- <button type="submit" class="searchButton">
              <i class="fa fa-search"></i>
            </button> -->
            </button>
          </div>

          <div class="PI-other">
            <h3>Tanaman lainnya</h3>
            <div
              class="PI-other-txt"
              v-for="(item, index) in dataSamping"
              :key="index"
            >
              <div class="PI-txt-left">
                <h6>{{ item.nama }}</h6>
                <p>{{ item.genus }}</p>
              </div>
              <a v-on:click="baca(item.nama)">
                <p>Baca lebih lanjut >>></p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import konten from "../../services/konten.service";

export default {
  name: "Tanaman Detail",
  data() {
    return {
      nama: this.$route.params.nama,
      loading: true,
      data: [],
      dataSamping: [],
    };
  },
  methods: {
    get() {
      konten
        .getDetail("tanaman", this.nama)
        .then((res) => {
          this.data = res.data.data;
          // this.loading = false;
          // console.log(res.data.data);
          konten
            .getLimit("tanaman", this.data.id, 4)
            .then((res) => {
              this.dataSamping = res.data.data;
              this.loading = false;
              // console.log(res.data.data);
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    baca(nama) {
      nama = nama.toLowerCase().replace(" ", "_");
      this.$router.push(`/agri/tanaman/${nama}`);
      // window.open("https://www.google.com");
    },
    back() {
      this.$router.push("/agri/tanaman");
    },
  },
  mounted() {
    this.get();
    var n = this.nama.toLowerCase().split("_");
    for (var i = 0; i < n.length; i++) {
      n[i] = n[i].charAt(0).toUpperCase() + n[i].substring(1);
    }
    n = n.join(" ");
    document.title = "Informasi Tanaman | " + n;
  },
};
</script>

<style scoped>
#PI {
  width: 100%;
  padding-top: 100px;
}

.load-enter-active,
.load-leave-active {
  transition: opacity 0.5s ease-in;
}

.load-enter,
.load-leave-to {
  opacity: 0;
}

.loading {
  position: fixed; /* or absolute */
  margin-top: -64px;
  margin-left: -64px;
  top: 50%;
  left: 50%;
  transition: display 0.5ms ease;
}

.loading img {
  max-width: 128px;
}

.PI-inner {
  width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: auto 350px;
  grid-template-rows: auto;
  grid-gap: 3em;
  max-width: 1180px;
}
.PI-left {
  width: 100%;
}

.PI-left h1 {
  color: #248842;
  width: 80%;
  font-weight: 400;
  border-bottom: 2px solid #248842;
  margin-bottom: 1em;
}

.PI-left h2 {
  font-size: 2rem;
  font-weight: 700;
  color: black;
}

.PI-left p {
  font-size: 1.15rem;
  margin-top: 0.5em;
  color: black;
}

.PI-left-mid {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.PI-time {
  color: #808080 !important;
  font-size: 0.9rem !important;
}

.PI-left-mid p {
  width: 100%;
  max-width: 200px;
  color: #808080;
  font-size: 3rem;
}

.PI-left-main {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 0.25em;
}

.PI-left-main img {
  width: 100%;
  /* padding: 10px; */
  max-width: 25%;
  max-height: auto;
  object-fit: contain;
  object-position: 0 0;
  margin-right: 1em;
}

.PI-left-main .PI-main-txt {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
}

.PI-txt-up {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.5em;
}

.PI-up-left {
  margin-right: 1em;
}

.PI-txt-down {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 0.5fr 1fr 1fr;
  grid-gap: 0.5em;
}

.PI-txt-down .PI-txt {
  grid-column: 1/4;
  grid-row: 1/2;
  width: 100%;
  display: flex;
  align-items: flex-end;
  color: #006837;
}

.PI-txt-down .PI-tem {
  grid-column: 1/2;
  grid-row: 2/3;
}

.PI-txt-down .PI-soilN {
  grid-column: 2/3;
  grid-row: 2/3;
}

.PI-txt-down .PI-int {
  grid-column: 3/4;
  grid-row: 2/3;
}

.PI-txt-down .PI-hum {
  grid-column: 1/2;
  grid-row: 3/4;
}

.PI-txt-down .PI-soilH {
  grid-column: 2/3;
  grid-row: 3/4;
}

.PI-txt-down .PI-ph {
  grid-column: 3/4;
  grid-row: 3/4;
}

.PI-desc {
  margin-top: 1em;
}

.PI-right {
  margin-left: 1em;
  width: 100%;
}

.PI-right .PI-search {
  width: 100%;
}

.PI-right .PI-other {
  width: 100%;
}

.PI-right .PI-other-txt {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.75em;
}

.PI-other-txt a {
  text-decoration: none;
  color: #808080;
  font-size: 1rem;
  cursor: pointer;
}

.PI-other-txt h6 {
  color: #006837;
}

.PI-other-txt p {
  color: #808080;
}
.PI-other h3 {
  font-weight: 400;
  margin-bottom: 0.75em;
  color: #248842;
}
.PI-inner h6,
.PI-inner h5 {
  font-size: 1.25rem;
}

.PI-txt h3 {
  font-size: 1.35rem;
}

.PI-search {
  margin-bottom: 2em;
}

/* search */

.search {
  width: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  padding: 1rem;
  border: 1.5px solid #006837;
  border-radius: 10px;
  background-color: white;
  color: black;
  transition: background-color 0.25s ease-in, color 0.25s ease-in;
  cursor: pointer;
}

.search:hover {
  background-color: #006837;
  color: white;
}

.search p {
  font-size: 1.25rem;
}

.searchTerm {
  width: 100%;
  border-radius: 20px;
  border: none;
  padding: 5px;
  outline: none;
  color: #9dbfaf;
}

.searchTerm:focus {
  color: black;
}

.searchButton {
  width: 40px;
  height: 36px;
  text-align: center;
  color: grey;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  outline: none;
}
@media only screen and (max-width: 1013px){
  .PI-inner {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    grid-gap: 3em;
  }
  .PI-right{
    margin-left: 0em;
    width: 95%;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 660px){
  .PI-left-main {
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }
  .PI-left-main img {
    max-width: 100%;
    margin-bottom: 1em;
  }
}
@media only screen and (max-width: 450px){
  .PI-left-main h6{
    font-size: 1.1rem;
  }
  .PI-left-main p{
    font-size: 1.2rem;
  }
  .PI-main-txt, .PI-txt-up{
    margin-bottom: 1em;
  }
  .PI-txt-down {
    grid-gap: 1em;
  }
}
</style>
